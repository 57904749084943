import ErrorPageHero from '../components/ErrorPageHero'
import PageComponent from '../components/Page'
import { getErrorPage } from '../lib/api'

export default function Page (props) {
  const { data: { page } } = props
  return (
    <PageComponent {...props}>
      <ErrorPageHero {...page} />
    </PageComponent>
  )
}

export async function getStaticProps (props) {
  const { locale, preview = false } = props
  const pageData = await getErrorPage('404', locale, preview)

  return {
    props: {
      data: pageData
    },
    revalidate: 60 * 60
  }
}
