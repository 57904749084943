import { createUseStyles } from '../helpers/createStyles'
import { theme } from '../styles/theme'
import Link from './StyledLink'
import ResponsiveImage from './ResponsiveImage'
import Section from './Section'

const ErrorPageHero = ({
  title,
  link,
  featuredImage
}) => {
  const styles = useStyles()

  return (
    <Section className={styles.section} fullWidth grid>
      <ResponsiveImage image={featuredImage} className={styles.image} />
      <div className={styles.content}>
        <h1 className={styles.title}>{title}</h1>
        {link && (
          <Link link={link} className={styles.link} />
        )}
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    height: '100vh',
    position: 'relative',
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    position: 'absolute !important',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  content: {
    position: 'relative',
    color: theme.colors.white,
    zIndex: theme.zIndex.imageContent,
    gridColumn: '4 / span 4',
    [theme.breakpoints.up('md')]: {
      gridColumn: '5 / span 6',
      maxWidth: 500
    }
  },
  title: {
    fontSize: 32,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(8),
      fontSize: 56
    }
  },
  link: {
    color: 'currentColor',
    textTransform: 'uppercase'
  }
})

export default ErrorPageHero
